import React from "react";
import { graphql } from "gatsby";
import { useTranslation, Link } from "gatsby-plugin-react-i18next";

import Layout from "../../components/Layout";
import MetaComponents from "../../components/seo/MetaComponents";
import ArticleStructuredData from "../../components/seo/ArticleStructuredData";
import Article from "../../components/Article";
import ArticleHeader from "../../components/ArticleHeader";
import MortgageCalculator from "../../components/MortgageCalculator";
import { urls, extendUrlWithSourceVersion } from "../../constants/urls";
import BreadcrumbList from "../../components/seo/BreadcrumbList";

const Anschlussfinanzierung = () => {
    const { t } = useTranslation();

    return (
        <Layout>
            <MetaComponents title={t("page.meta.title")} description={t("page.meta.description")} />
            <ArticleHeader h1Title="Anschlussfinanzierung – was ist das und wie wird’s gemacht?" showCalc={false} />
            <Article>
                <p>
                    Die Zinsbindungsphase deines Kredits kommt langsam an ihr Ende, du hast aber noch Restschulden und
                    bist dir nicht sicher, welches Modell du wählen sollst, um diese zu begleichen? Welche Möglichkeiten
                    du bei einer solchen Anschlussfinanzierung hast, erklären wir dir in diesem Beitrag.
                </p>
                <hr />

                <h2>Was versteht man unter einer Anschlussfinanzierung?</h2>
                <p>
                    Ganz allgemein handelt es sich hierbei um eine Finanzierung, mit der du Schulden zurückzahlst, die
                    über das Ende der Zinsbindungsphase deines{" "}
                    <Link to="/artikel/kredit/" target="_blank" rel="noreferrer noopener">
                        Kredits
                    </Link>{" "}
                    hinaus bestehen. Es lassen sich zwei Varianten unterscheiden: Prolongation und{" "}
                    <Link to="/dienstleistungen/umschuldung/" target="_blank" rel="noreferrer noopener">
                        Umschuldung
                    </Link>{" "}
                    . Was die Unterschiede sind? Das erklären wir dir im Folgenden!
                </p>

                <h2>Was ist eine Prolongation?</h2>
                <p>
                    Bei einer Prolongation (Verlängerung) deines Kredits nimmst du für die Finanzierung der
                    verbleibenden Summe ein Angebot deines bisherigen Kreditgebers an. Du unterschreibst erneut eine
                    Zinsvereinbarung und dein aktueller Kredit wird mit veränderten Konditionen fortgesetzt. Im
                    Normalfall legen Banken ihren Kund*innen noch vor Ablauf der Fixzinsbindung ein solches Angebot vor.
                    Doch Achtung: Zwar ist es für dich mit minimalem Aufwand verbunden, den bestehenden Kredit einfach
                    zu verlängern. Wenn du mehrere Optionen vergleichst, wirst du jedoch rasch bemerken, dass die
                    Konditionen deiner Bank nicht die günstigsten sind. Sieh dich also in jedem Fall nach Alternativen
                    um! Wir von miracl helfen dir dabei!
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Jetzt Kreditvergleich anfordern!
                    </a>
                </p>
                <hr />

                <h2>Was ist eine Umschuldung?</h2>
                <p>
                    Anders als bei der Prolongation ersetzt du bei einer Umschuldung deinen bestehenden Kredit durch den
                    eines anderen Anbieters. Dieser löst die aktuelle Finanzierung nach Ende der Zinsbindung ab und
                    erhält dafür die Höchstbetragshypothek an der Immobilie, die ihm als Sicherheit dient. Auch wenn bei
                    einer Umschuldung Grundbuchgebühren, Beglaubigungskosten und - je nach Kreditvertrag - auch Pönalen
                    anfallen, kann sie sich schon bei einer Zinsersparnis lohnen, die im Zehntelprozent-Bereich liegt.
                </p>
                <hr />

                <h2>Wie laufen Prolongation und Umschuldung ab?</h2>
                <h3>Durchführung einer Prolongation</h3>
                <p>
                    Üblicherweise legt dir deine Bank drei Monate vor dem Auslaufen deines Wohnkredits eine
                    entsprechendes Angebot vor. Alternativ kannst du dieses einige Monate vor Ende der Fixzinsbindung
                    selbst beantragen. Bist du mit den Konditionen einverstanden, dann musst du nichts anderes tun, als
                    einen neuen Kreditvertrag zu unterzeichnen. In diesem werden{" "}
                    <Link to="/artikel/zinsen/" target="_blank" rel="noreferrer noopener">
                        Zinssatz
                    </Link>{" "}
                    und Laufzeit erneut festgelegt, die{" "}
                    <Link to="/artikel/tilgung/" target="_blank" rel="noreferrer noopener">
                        Tilgung
                    </Link>{" "}
                    kann ebenfalls angepasst werden.
                </p>
                <h3>Durchführung einer Umschuldung</h3>
                <p>
                    Wie wir gesehen haben, wechselst du im Falle einer Umschuldung das Kreditinstitut. Hierfür müssen
                    der neuen Bank folgende Dokumente vorgelegt werden:
                </p>
                <ul>
                    <li>Einkommensnachweis (Einkommensbescheid sowie Gehaltsabrechnungen der letzten drei Monate</li>
                    <li>Grundbuchauszug </li>
                    <li>Unterlagen über die Immobilie als solche</li>
                    <li>Nachweis über geplante und abgeschlossene Sanierungen</li>
                    <li>Kreditvertrag mit der bisherigen Bank</li>
                    <li>Bestätigung der Restschulden zum Ablösetermin</li>
                </ul>
                <p>
                    Ist der Vertrag mit dem neuen Anbieter erst einmal unterzeichnet, so klärt dieser die übrigen
                    Formalitäten mit dem bisherigen Kreditgeber. Du selbst musst also nichts weiter tun
                </p>
                <hr />

                <h2>Pro und Contra Prolongation</h2>
                <p>
                    Das Pro steckt hier schon im Namen - also müsste doch alles für die Verlängerung deines bestehenden
                    Kredits sprechen, oder? Nicht so ganz. Die Vorteile der Prolongation liegen insbesondere in der
                    unkomplizierten Durchführung: Die für die{" "}
                    <Link to="/artikel/immobilienfinanzierung/" target="_blank" rel="noreferrer noopener">
                        Immobilienfinanzierung
                    </Link>{" "}
                    erforderlichen Dokumente müssen nicht neuerlich vorgelegt und es müssen keine weiteren Nachweise
                    erbracht werden. Nach der Unterzeichnung des neuen Vertrags musst du keine weiteren Schritte setzen.
                    Umgekehrt begibst du dich bei einer Prolongation allerdings auch der Möglichkeit, bei der
                    Anschlussfinanzierung attraktivere Konditionen zu erzielen. Denn als Kreditnehmer*in hast du hier in
                    dem meisten Fällen relativ wenig Verhandlungsspielraum.
                </p>
                <hr />

                <h2>Pro und Contra Umschuldung</h2>
                <p>
                    Die Umschuldung hingegen bietet gerade den Vorteil, dass du mit dem Wechsel des Kreditinstituts und
                    dem damit verbundenen Vergleich von Kreditangeboten günstigere Konditionen erzielen kannst. Zudem
                    kannst du oft Kosten sparen und bist deine Schulden nicht selten schneller los als bei einer
                    Prolongation. Was du an Geld sparst, musst du allerdings an Zeit investieren: Umschuldungen sind mit
                    einem höheren bürokratischen Aufwand verbunden und verlangen dir als Konsument*in mehr
                    Eigeninitiative ab. Zudem gibt es auch hier einige Kosten, die es zu beachten gilt: etwa die Kosten
                    für die Ablösung der Höchstpreishypothek durch den neuen Kreditgeber sowie etwaige Pönale.
                </p>
                <hr />

                <h2>Welche Variante passt zu mir?</h2>
                <p>
                    Da diese Frage von zahlreichen Faktoren abhängt, die von Kreditnehmer*in zu Kreditnehmer*in stark
                    variieren, ist sie kaum pauschal zu beantworten. Um herauszufinden, ob du eher Typ Umschuldung oder
                    Typ Prolongation bist, sprich am besten mit einem Berater, der Erfahrung mit dem Thema hat. Dann
                    fällt es dir bestimmt leicht, die richtige Entscheidung treffen.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Zum kostenlosen Erstgespräch!
                    </a>
                </p>
            </Article>
            <MortgageCalculator noTopMargin defaultTerm={35} defaultMortgage={50000} />

            <BreadcrumbList page={"anschlussfinanzierung"}></BreadcrumbList>
            <ArticleStructuredData
                page={"anschlussfinanzierung"}
                heading={"Anschlussfinanzierung – was ist das und wie wird’s gemacht?"}
            />
        </Layout>
    );
};

export default Anschlussfinanzierung;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(
            filter: {
                ns: { in: ["header", "footer", "page.artikel.anschlussfinanzierung", "mortgageCalc"] }
                language: { eq: $language }
            }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
